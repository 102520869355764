import React from 'react';

import{ images } from '../../constants';
import { SubHeading } from '../../components';
import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
              <img src={images.chef} alt="chef" />
    </div>

    <div className="app__wrapper_info">
        
        <h1 className="headtext__cormorant"> What we Believe, chef's word</h1>

         <div className="app__chef-content">
          <div className="app__chef-content_quote">
            <img src={images.quote} alt="quote" />
            <p className="p__opensans">In our kitchen, we turn nature's bounty into culinary poetry. Every dish is a love letter to the flavors of our region, crafted with passion and a commitment to sustainability</p>
          </div>
         
         </div>
         <div className=" app__chef-sign">
          <p> Kevin Madi</p>
          <p className="p__opensans">Chef & Founder</p>
            <img src={images.sign} alt="sign" />
         </div>
    </div>
  </div>
);

export default Chef;
