import React from 'react';

import { images } from '../../constants';
import { SubHeading } from '../../components';
import './Header.css';


const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
          
          <h1 className="app__header-h1">Where Every Bite is a Delight</h1>
          
          <div className="button"><button type="button" className="custom__button">Explore Menu</button>
          </div>
      </div>
      <div className="app__wrapper_img">
        <img src={images.welcome} alt="header img" />
      </div>
  </div>
);

export default Header;
                                                                                                                