import bg from '../assets/bg.png';
import chef from '../assets/chef.jpg';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import cutter from '../assets/cutter.png';
import menu from '../assets/menu.png';
import welcome from '../assets/welcome.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import greenwich from '../assets/greenwich.png';


export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  cutter,
  menu,
  welcome,
  sign,
  quote,
  greenwich,
};
