import React from 'react';


import './AboutUs.css';
import { images } from '../../constants';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id='about'>
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="g letter" />
      </div>
        <div className="app__aboutus-content flex__center">
        <div className="app__aboutus-content_about">
          <h1 className="headtext__cormorant">About Us</h1>
          
          <p className="p__opensans">Greenwich is where freshness meets flavor. We're a culinary haven in Vancouver, dedicated to serving up the finest in farm-to-table dining. Our mission is simple: celebrate nature's bounty in every dish we create.</p>
          <button type="button" className="custom__button">Know More</button>
        </div>
        <div className="app__aboutus-content_cutter flex__center">
           <img src={images.cutter} alt="about_cutter" />
        </div>

        <div className="app__aboutus-content_history">
          <h1 className="headtext__cormorant">Our History</h1>
          
          <p className="p__opensans">Established in 2023, Greenwich has grown from a humble idea to a beloved dining destination. We take pride in sourcing locally, supporting our farmers, and crafting seasonal delights. Our story is one of passion, sustainability, and exceptional cuisine, and we can't wait to share it with you.</p>
          <button type="button" className="custom__button">Know More</button>
        </div>


        </div>
    
  </div>
);

export default AboutUs;
