import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram} from 'react-icons/fi';
import {FooterOverlay, Newsletter } from '../../components';
import './Footer.css';
import { images } from '../../constants';

const Footer = () => (
  <div id='footer'>
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">

      <div className="app__footer-links_contact"> 
          <h1 className="app__footer-headtext"> contact Us</h1>
          <p className="p__opensans"> Vnacouver,BC</p>
          <p className="p__opensans">+1-800-515-6655 </p>
          <p className="p__opensans">604-555-55-15</p>

      </div>
      <div className="app__footer-links-logo">
        <img src={images.greenwich} alt="footer_logo" />
        <p className="p__opensans"> From Farm to Plate: The Greenwich Promise</p>
        
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram /> 
        </div>

      </div>

      <div className="app__footer-links_work">
      <h1 className="app__footer-headtext"> Working Hours</h1>
          <p className="p__opensans"> Monday - Friday</p>
          <p className="p__opensans">10:00 am- 11:00 pm </p>
          <p className="p__opensans">Saturday - Sunday</p>
          <p className="p__opensans">9:00 am - 1:00 am</p>

      </div>

    </div>
              <div className="footer__copyright">
                <p className="p__opensans"> 2023 all rights reserved </p>
              </div>

  </div>
);

export default Footer;
